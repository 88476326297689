<template>
    <UiPopupCard
        v-model="isOpen"
        :anchor="anchor"
        container-class="sim-search-popup"
        :offset="12"
        no-tip
        match-min-anchor-width
    >
        <!--  LOADING OR NO RESULTS  -->
        <template v-if="areNoResults || isLoading">
            <!--  LOADING  -->
            <CoreIconLoadingSpinner v-if="isLoading" class="absolute-center" small />

            <!--  NO RESULTS  -->
            <div v-visibility="areNoResults" class="p-5 text-center">
                <h5 class="sim-h sim-h5 mb-6">
                    {{ $t('search.no_results') }}
                </h5>
                <p class="text-normal">
                    {{ $t('search.try_different_term') }}
                </p>
            </div>
        </template>

        <!--  MAIN CONTENT  -->
        <template v-else>

            <!--  CATEGORIES  -->
            <div class="mb-5">
                <div class="sim-text-medium sim-font-medium mb-3.5 flex items-center gap-3">
                    {{ $t('labels.categories') }}
                    <hr class="my-0">
                </div>

                <div v-if="categories.length" class="mb-7 flex flex-wrap gap-3">
                    <NuxtLink v-for="category in categories"
                              :key="category.id!"
                              :to="category.urls ?? ''"
                    >
                        <UiCardWithImage
                            tag="div"
                            :src="category.thumbnailImageUrl ?? category.imageUrl"
                            small
                            floating
                            hover
                        >
                            {{ category.name }}
                        </UiCardWithImage>
                    </NuxtLink>
                </div>

                <p v-else>
                    {{ $t('search.no_categories_found') }}
                </p>
            </div>

            <!--  PRODUCTS  -->
            <div class="mb-5">
                <div class="sim-text-medium sim-font-medium mb-3.5 flex items-center gap-3">
                    {{ $t('labels.products') }}
                    <hr class="my-0">
                </div>

                <div v-if="products.length" class="sim-scrollbar flex max-h-[25rem] flex-col gap-1.5 overflow-y-auto p-2">
                    <NuxtLink v-for="product in products"
                              :key="product.id!"
                              :to="product.getUrl() ?? ''"
                    >
                        <ProductCardInline :product="product" />
                    </NuxtLink>
                </div>

                <p v-else>
                    {{ $t('search.no_products_found') }}
                </p>
            </div>

            <!--  BUTTON  -->
            <!--  TODO: add back when the search results page is implemented  -->
<!--            <BaseUiButton color="primary" class="w-full">-->
<!--                <template #leading>-->
<!--                    <IconSearch />-->
<!--                </template>-->

<!--                {{ $t('search.show_all_results') }}-->
<!--            </BaseUiButton>-->
        </template>

    </UiPopupCard>
</template>

<script lang="ts" setup>

const {
    query,
    anchor,
} = defineProps<{
    query: string
    anchor: HTMLElement
}>()

const isOpen = defineModel<boolean>()
const searchQuery = computed(() => query)

// DATA FETCHING -------------------------------------------------------------------------------------------------------

const { data: productsResponse, execute: fetchProducts, status: productsFetchStatus } = useProductsApiService()
    .sortByAsc('relevance' as any)
    .setPerPage(5)
    .setParam('search', searchQuery)
    .embed([
        ProductModel.EMBED_COMPUTED_TAXED_PRICES,
        ProductModel.EMBED_DEFAULT_IMAGE_URL,
        ProductModel.EMBED_URLS,
    ])
    .useGet({
        immediate: false,
        watch: false,
    })
const products = computed(() => productsResponse.value?.getItems() ?? [])

const { data: categoriesResponse, execute: fetchCategories, status: categoriesFetchStatus } = useCategoriesApiService()
    .whereContains(CategoryModel.ATTR_NAME, searchQuery)
    .embed([
        CategoryModel.EMBED_THUMBNAIL_IMAGE_URL,
        CategoryModel.EMBED_IMAGE_URL,
        CategoryModel.EMBED_URLS,
    ])
    .setPerPage(8)
    .enablePagination()
    .useGet({
        immediate: false,
        watch: false,
    })
const categories = computed(() => categoriesResponse.value?.getItems() ?? [])

// ---------------------------------------------------------------------------------------------------------------------

const _isFetching = computed<boolean>(() => productsFetchStatus.value === 'pending' || categoriesFetchStatus.value === 'pending')
const _initialLoadingAfterOpening = ref<boolean>(false)


// disable the initial loading flag when fetching is done to only show the loading when the data is really
// being fetched
watch(_isFetching, (isFetching) => {
    if (isFetching) return
    _initialLoadingAfterOpening.value = false
})

/**
 * Whether the popup should show a loading spinner.
 */
const isLoading = computed<boolean>(() => _isFetching.value || _initialLoadingAfterOpening.value)
/**
 * Whether there are no results from both endpoints.
 */
const areNoResults = computed<boolean>(() =>
    !products.value.length &&
    !categories.value.length &&
    !isLoading.value
)

/**
 * A function that fetches products and categories based on the current query.
 * !! Do not use this function directly, use its debounced version instead.
 */
async function handleSearch() {
    if (!query) return

    await Promise.all([
        fetchProducts(),
        fetchCategories(),
    ])
}

/**
 * A debounced version of the handleSearch function.
 * @see handleSearch
 */
const debouncedHandleSearch = useDebounceFn(handleSearch, 500)

let ignoreNextOpening = false

// show loading every time the popup is opened
watch(isOpen, (isOpen) => {
    if (!isOpen) {
        ignoreNextOpening = false
        return
    }

    _initialLoadingAfterOpening.value = true

    if (query && !ignoreNextOpening) handleSearch()
}, { immediate: true })

// execute search
watch(() => query, (val) => {
    if (val && isOpen.value) {
        debouncedHandleSearch()
    }

    isOpen.value = !!val
    ignoreNextOpening = true
})

</script>

<style lang="scss" scoped>

.sim-search-popup {
    width: max(100%, 32rem);
}


</style>
